import { useDeleteAPI, useGetAPI, usePatchAPI, usePostAPI } from '@api/useAPI';
import {
	AddressSearchResponse,
	PostcodeParseResponse,
	ParseAddressResponse,
	ServerFlag,
	AppEnums,
	TLocation,
	TLocationContact,
	AJAXError,
	TDocument,
	TIssueAndClaimDocument,
	CustomerType, ShipmentApiCustomerType
} from '@types';
import axios from 'axios';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { SID } from '@state/App.ts';
import { PaginatedList, RateCardListItem } from './RateCards/useLaneRateCards';

/*
 *  Dummy Fetch for XSRF token
 */

export const useGetXSRF = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({
		endpoint: '/login',
		domain: 'Inertia'
	});

	return {
		isLoading,
		xsrf: data,
		error,
		callGetXSRF: callAPI
	}
}

/*
 *  ENUM Fetch
 */

export const useGetEnums = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({
		endpoint: '/api/enum',
		domain: 'AdminAPI'
	});

	return {
		isLoading,
		enums: data as { data: AppEnums[] },
		error,
		callGetEnums: callAPI
	}
}

export const useGetEOSEnums = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/v2/super-dock/enums', });

	return {
		isEosEnumLoading: isLoading,
		eosEnums: data,
		eosEnumError: error,
		callGetEOSEnums: callAPI
	}
}

/*
 *	Feature Flag Fetch
 *	NOTE: The URL here is only for testing...
 *	we may need to update this in the future...
 */

export const useGetFeatureFlags = (key:string, secret:string) => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({
		endpoint: `/api/flags?key=${key}&secret=${secret}`,
		domain: 'AdminAPI'
	});

	return {
		isLoading,
		featureFlags: data as ServerFlag[],
		ffError: error,
		callGetFeatureFlags: callAPI
	}
}

/*
 *  GeoJSON Calls
 */

export const useGetRegionPolygonAndExtent = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/region-polygon?region={1}' });

	return {
		isLoading,
		regionPolygonsAndExtents: data,
		regionPolygonExtentError: error,
		callGetRegionPolygonAndExtent: callAPI
	}
}

/**
 * Fetch current user(me) data
 */
export const useGetMe = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: `/api/me` });

	return {
		isLoadingMe: isLoading,
		me: data,
		meError: error,
		callGetMe: callAPI
	}
}

/*
 *  Fetch User/Team Data
 */

export const useGetTeam = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: `/api/team` });

	return {
		isLoadingTeam: isLoading,
		team: data,
		teamError: error,
		callGetTeam: callAPI
	}
}


export const useGetTeamABMList = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: `/api/admin/team/abm-list` });

	return {
		isLoadingTeam: isLoading,
		team: data,
		teamError: error,
		callGetTeam: callAPI
	}
}


/*
 *	EOS API - We need to filter address/region/postcode on eos database
 */
export const useGetAutocomplete = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({
		endpoint: '/api/autocomplete{1}',
		domain: 'ShipmentAPI'
	});
	const triggerAPI = (term: string, locality_type?: string) => {
		let params = `?term=${term}`;
		if (locality_type) {
			/*
			 *	Currently the AddressPicker component only allows for one locality type at a time (string)
			 *	will need to come back and update if its required to send 2 types,
			 *	(To send all 3, simply don't include the locality_type in the first place!)
			 */
			params += `&${encodeURIComponent('locality_type[]')}=${locality_type}`;
		}
		callAPI({ routes: [params] });
	}
	return {
		loadingAutocomplete: isLoading,
		addresses: data as AddressSearchResponse,
		getAutocompleteError: error as AJAXError,
		callGetAutocomplete: triggerAPI
	}
}

export const useGetParseAddress = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/address-api/address/parse?address={1}' });

	return {
		loadingParseAddress: isLoading,
		parsedAddress: data as ParseAddressResponse,
		getParseAddressError: error,
		callGetParseAddress: callAPI
	}
}

export const useGetParsePostcode = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/address-api/postcode/parse?postcode={1}' });

	return {
		loadingParsePostcode: isLoading,
		parsedPostcode: data as PostcodeParseResponse,
		getParsePostcodeError: error,
		callGetParsePostcode: callAPI
	}
}

export const useGetParseRegion = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/address-api/region/brief/by-name/{1}' });

	return {
		loadingParseRegion: isLoading,
		parsedRegion: data as PostcodeParseResponse,
		getParseRegionError: error,
		callGetParseRegion: callAPI
	}
}


export const useGetAdminLocations = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/locations' });

	return {
		loadingLocation: isLoading,
		location: data as {
			rows: TLocation[];
			perPage: number;
			totalRows: number;
			currentPage: number;
		},
		locationError: error,
		clearLocationError: clearError,
		callGetLocation: callAPI
	}
}
export const useGetLocations = () => {
	const sID = useRecoilValue(SID);
	const headers = sID ? { 'X-Shipper-Token': `${sID}` } : undefined;

	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = useGetAPI({
		endpoint: '/api/locations', headers: headers, avoidCache: true
	});

	return {
		loadingLocation: isLoading,
		location: data as {
			rows: TLocation[];
			perPage: number;
			totalRows: number;
			currentPage: number;
		},
		locationError: error,
		clearLocationError: clearError,
		callGetLocation: callAPI
	}
}

export const useGetLocation = () => {
	const sID = useRecoilValue(SID);
	const headers = sID ? { 'X-Shipper-Token': `${sID}` } : undefined;

	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = useGetAPI({ endpoint: '/api/locations/{1}', headers });

	return {
		loadingLocation: isLoading,
		location: data as TLocation,
		locationError: error,
		clearLocationError: clearError,
		callGetLocation: callAPI
	}
}

export const useGetAdminLocation = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/locations/{1}' });

	return {
		loadingLocation: isLoading,
		location: data as TLocation,
		locationError: error,
		clearLocationError: clearError,
		callGetLocation: callAPI
	}
}

export const useUpdateAdminLocation = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = usePatchAPI({ endpoint: '/api/admin/locations/{1}' });

	return {
		loadingUpdateLocation: isLoading,
		updateLocation: data as TLocation,
		updateLocationError: error as AJAXError,
		clearUpdateLocationError: clearError,
		callUpdateLocation: callAPI
	}
}

export const useCreateAdminLocation = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = usePostAPI({ endpoint: '/api/admin/locations' });

	return {
		loadingCreateLocation: isLoading,
		createLocation: data as TLocation,
		createLocationError: error as AJAXError,
		clearCreateLocationError: clearError,
		callCreateLocation: callAPI
	}
}

export const useGetLocationContacts = (id: string) => {
	const sID = useRecoilValue(SID);
	const headers = sID ? { 'X-Shipper-Token': `${sID}` } : undefined;

	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = useGetAPI({ endpoint: '/api/locations/{1}/contacts', headers: headers });

	const callGetContacts = () => {
		callAPI({ routes: [id] });
	};

	return {
		loading: isLoading,
		contacts: data as {data: TLocationContact[]},
		error: error,
		clearError: clearError,
		callGetContacts: callGetContacts
	}
}

export const useGetAdminLocationContacts = (id: string) => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/locations/{1}/contacts', });

	const callGetContacts = () => {
		callAPI({ routes: [id] });
	};

	return {
		loading: isLoading,
		contacts: data as { data: TLocationContact[] },
		error: error,
		clearError: clearError,
		callGetContacts: callGetContacts
	}
}

export const useDeleteLocationContact = (locationId: string) => {
	const sID = useRecoilValue(SID);
	const headers = sID ? { 'X-Shipper-Token': `${sID}` } : undefined;

	const {
		isLoading,
		error,
		data,
		clearError,
		callAPI
	} = useDeleteAPI({ endpoint: '/api/locations/{1}/contacts/{2}', headers });

	const deleteContact = async (contact: TLocationContact) => {
		await callAPI({ routes: [locationId, String(contact.id!)] });
	};

	return {
		deleting: isLoading,
		error: error,
		data,
		clearError: clearError,
		deleteContact
	}
}

export const useDeleteAdminLocationContact = (locationId: string) => {
	const {
		isLoading,
		error,
		data,
		clearError,
		callAPI
	} = useDeleteAPI({ endpoint: '/api/admin/locations/{1}/contacts/{2}' });

	const deleteContact = async (contact: TLocationContact) => {
		await callAPI({ routes: [locationId, String(contact.id!)] });
	};

	return {
		deleting: isLoading,
		error: error,
		data,
		clearError: clearError,
		deleteContact
	}
}

export const usePostLocationContacts = (id: string) => {
	const sID = useRecoilValue(SID);
	const headers = sID ? { 'X-Shipper-Token': `${sID}` } : undefined;

	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = usePostAPI({ endpoint: '/api/locations/{1}/contacts', headers });

	const saveContact = async (contacts: TLocationContact) => {
		await callAPI({
			routes: [id],
			payload: { form: contacts }
		});
	}

	return {
		loading: isLoading,
		contacts: data as TLocationContact[],
		error: error,
		clearError: clearError,
		postContacts: saveContact
	}
}

export const usePostAdminLocationContacts = (id: string) => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = usePostAPI({ endpoint: '/api/admin/locations/{1}/contacts' });

	const saveContact = async (contacts: TLocationContact) => {
		await callAPI({
			routes: [id],
			payload: { form: contacts }
		});
	}

	return {
		loading: isLoading,
		contacts: data as TLocationContact[],
		error: error,
		clearError: clearError,
		postContacts: saveContact
	}
}


export const useGetAdminLocationConnectedShippers = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/locations/{1}/shippers' });
	return {
		loading: isLoading,
		connectedShippers: data?.data ?? [],
		error: error,
		clearError: clearError,
		callGetConnectedShippers: callAPI
	}
}

export const usePostAdminLocationConnectedShippers = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = usePostAPI(
		{ endpoint: '/api/admin/locations/{1}/shippers' });
	return {
		loading: isLoading,
		connectedShippers: data,
		error: error,
		clearError: clearError,
		postConnectedShippers: callAPI
	}
}

export const usePostLocationChangeRequest = () => {
	const sID = useRecoilValue(SID);
	const headers = sID ? { 'X-Shipper-Token': `${sID}` } : undefined;

	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = usePostAPI({
		endpoint: '/api/locations/{1}/request-change',
		headers
	});

	const triggerAPI = async (id: string, payload: any) => {
		await callAPI({ routes: [id], payload });
	}

	return {
		loadingChangeRequest: isLoading,
		changeRequestData: data as {
			rows: TLocation[];
			perPage: number;
			totalRows: number;
			currentPage: number;
		},
		changeRequestError: error,
		clearChangeRequestError: clearError,
		callPostChangeRequest: triggerAPI
	}
}

export const useDeleteAdminLocation = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useDeleteAPI({ endpoint: '/api/admin/locations/{1}' });

	return {
		loadingDeleteLocation: isLoading,
		deletedLocation: data as {
			id: string;
		},
		deleteLocationError: error as AJAXError,
		callDeleteLocation: callAPI
	}
}


export const useGetDocument = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({
		endpoint: '/documents/{1}?format={2}',
		domain: 'Inertia'
	});

	const triggerAPI = (id: number, format: string) => {
		callAPI({ routes: [`${id}`, `${format}`] });
	}
	return {
		loadingGetDocument: isLoading,
		document: data as string,
		getDocumentError: error,
		callGetDocument: triggerAPI
	}
}

export const useGetMultipleDocuments = () => {
	const {
		isLoading,
		error,
	} = useGetAPI({
		endpoint: '/documents/{1}?format={2}',
		domain: 'Inertia'
	});
	const [retData, setRetData] = useState<any | null>(null);

	const downloadDocument = async (id: number, format: string) => {
		return await axios.get(`/inertia/documents/${id}?format=${format}`)
			.then((res) => {
				return res.data as string
			});
	}

	const triggerAPI = async (documents: TIssueAndClaimDocument[], format: string) => {
		const documentPromises = documents?.map(async (document) => {
			const documentUri = await downloadDocument(document.doc_id, format);
			return {
				uri: documentUri,
				fileType: document.document.object_type,
			};
		}) ?? [];

		const docsToSend = await Promise.all(documentPromises);
		setRetData(docsToSend);

	}
	const getData = () => {
		if (!retData) return null;
		return retData as any;
	}
	return {
		loadingGetDocuments: isLoading,
		documents: getData(),
		getDocumentsError: error,
		callGetDocuments: triggerAPI
	}
}

export const usePostDocument = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = usePostAPI({
		endpoint: '/documents',
		headers: { 'Content-Type': 'multipart/form-data', },
		domain: 'Inertia'
	});

	const triggerAPI = (payload: FormData) => {
		callAPI({ payload });
	}

	return {
		loadingPostDocument: isLoading,
		postDocumentData: data as {data: TDocument},
		getPostDocumentError: error,
		callPostDocument: triggerAPI
	}
}

export const useGetAllCustomerLanes = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({
		endpoint: `/api/lanes/{1}/all?{2}`,
		domain: 'ShipmentAPI'
	});

	const triggerAPI = (customerType: CustomerType | ShipmentApiCustomerType, params: string = 'page=1&perPage=25') => {
		callAPI({ routes: [customerType, params] });
	}

	return {
		isLoading: isLoading,
		data: data as PaginatedList<RateCardListItem>,
		error: error,
		triggerAPI: triggerAPI
	}
}
